import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updatesSite, updateSimNavi, exportPNG, download } from '../../slices/siteSlice'
import * as ABB from '@abb/abb-common-ux-react'
import '@abb/abb-common-ux-react/styles.css'
import '../../App.css'
import TimeseriesDropzoneSelector from './../resources/timeseriesDropzoneSelector'
import Addition_Button from './addition_button'
import General_data from './../mining_components/general_data'
import captureElementAsBase64 from './../pdf_export/png_export_function'
import { emptyStringToNull } from './../resources/utilities'

const User_steps = () => {
    const dispatch = useDispatch()
    const ident = useSelector(state => state.site.ident)
    const id = ident.id
    const timeseriesFiles = useSelector(state => state.timeseriesFiles)
    const mine_layout = useSelector(state => state.site.mine_layout)
    const grid = useSelector(state => state.site.grid)
    const simStates = useSelector(state => state.site.simStates)

    // update data of site in backend
    const updateDataBackend = (site, update) => {
        dispatch(updatesSite({ 'id': id, 'updates': [{ 'slice': site, ...update }] }))
    }

    const Generate = () => {
    }
        

    // update data of site in backend
    const ResetDataBackend = () => {
        dispatch(updatesSite({
            'id': id, 'updates': [
                { 'slice': 'mine_layout', key: 'hoisting_load', value: null },
                { 'slice': 'mine_layout', key: 'hoisting_color', value: false },
                { 'slice': 'mine_layout', key: 'drilling_load', value: null },
                { 'slice': 'mine_layout', key: 'drilling_color', value: false },
                { 'slice': 'mine_layout', key: 'ventilation_load', value: null },
                { 'slice': 'mine_layout', key: 'ventilation_color', value: false },
                { 'slice': 'mine_layout', key: 'transport_load', value: null },
                { 'slice': 'mine_layout', key: 'transport_color', value: false },
                { 'slice': 'mine_layout', key: 'dewatering_load', value: null },
                { 'slice': 'mine_layout', key: 'dewatering_color', value: false },
                { 'slice': 'mine_layout', key: 'comminution_load', value: null },
                { 'slice': 'mine_layout', key: 'comminution_color', value: false },
                { 'slice': 'mine_layout', key: 'crushing_load', value: null },
                { 'slice': 'mine_layout', key: 'crushing_color', value: false },
                { 'slice': 'mine_layout', key: 'total_load', value: null },
                { 'slice': 'mine_layout', key: 'total_load_available', value: 'no' },
                { 'slice': 'mine_layout', key: 'res_color', value: false },
                { 'slice': 'grid', key: 'load_series_selector', value: null },
                { 'slice': 'grid', key: 'load_visualisation_selector', value: null },
                { 'slice': 'grid', key: 'pv_series_selector', value: null },
                { 'slice': 'grid', key: 'pv_visualisation_selector', value: null },
                { 'slice': 'wind', key: 'wind_series_selector', value: null },
                { 'slice': 'wind', key: 'wind_visualisation_selector', value: null },
                { 'slice': 'wind', key: 'wind_visualisation_selector', value: null },
                //{'slice': 'simStates', key: 'simulation1_state', value: 'empty'},

            ]
        }))
    }


    if (mine_layout.total_load_available == 'no' && grid.load_series_selector != null) {
        dispatch(updatesSite({ 'id': id, 'updates': [{ 'slice': 'grid', 'key': 'load_series_selector', value: null }] }))
    }

    //to enable timeseries selection in the graph
    const fileList = timeseriesFiles.status == 'loaded' ? timeseriesFiles.timeseries.map((t, i) => ({ lab: t.filename, val: i })) : [{ lab: 'empty', val: 0 }]

    const updateSeriesSelector = update => {
        dispatch(updatesSite({ 'id': id, 'updates': [{ 'slice': 'grid', ...update }] }))
    }



    const customHoisting = v => {
        const newvalueCurrent = !mine_layout.hoisting_color
        dispatch(updatesSite({'id': id, 'updates': [{'slice': 'mine_layout', key: 'hoisting_color', value: newvalueCurrent}]}))
        //dispatch(super_hoisting({ 'daily_production': mine_layout.hoisting_daily_mass, 'depth': mine_layout.hoisting_depth, 'std': mine_layout.hoisting_std, 'resolution': mine_layout.hours, 'id': id, 'updates': [{ 'slice': 'mine_layout', key: 'hoisting_color', value: newvalueCurrent }] }))
    }


    const customVentilation = v => {
        const newvalueCurrent = !mine_layout.ventilation_color
        dispatch(updatesSite({'id': id, 'updates': [{'slice': 'mine_layout', key: 'ventilation_color', value: newvalueCurrent}]}))
        //dispatch(super_ventilation({
        //    'volume': mine_layout.ventilation_volume, 'depth': mine_layout.ventilation_depth, 'resolution': mine_layout.hours, 'std': mine_layout.ventilation_std, 'mass': mine_layout.ventilation_tons,
        //    'id': id, 'updates': [{ 'slice': 'mine_layout', key: 'ventilation_color', value: newvalueCurrent }]
        //}))

    }


    const customCrushing = v => {
        const newvalueCurrent = !mine_layout.crushing_color
        dispatch(updatesSite({ 'id': id, 'updates': [{ 'slice': 'mine_layout', key: 'crushing_color', value: newvalueCurrent }] }))
        //dispatch(super_load_constructor({
        //    'num_processes': mine_layout.crushing_processes, 'avg': mine_layout.crushing_avg, 'resolution': mine_layout.hours, 'std': mine_layout.crushing_std, 'element_name': 'crushing', 'tons_per_day': mine_layout.crushing_tons
        //    , 'id': id, 'updates': [{ 'slice': 'mine_layout', key: 'crushing_color', value: newvalueCurrent }]
        //}))
    }

    const customTransport = v => {
        const newvalueCurrent = !mine_layout.transport_color
        //dispatch(super_load_constructor({ 'num_processes': mine_layout.transport_processes, 'avg': mine_layout.transport_avg, 'resolution': mine_layout.hours, 'std': mine_layout.transport_std, 'element_name': 'transport', 'tons_per_day': mine_layout.transport_tons, 'id': id, 'updates': [{ 'slice': 'mine_layout', key: 'transport_color', value: newvalueCurrent }] }))
        dispatch(updatesSite({ 'id': id, 'updates': [{ 'slice': 'mine_layout', key: 'transport_color', value: newvalueCurrent }] }))
    }

    const customDewatering = v => {
        const newvalueCurrent = !mine_layout.dewatering_color
        //dispatch(super_dewatering({ 'mass': mine_layout.dewatering_mass, 'height': mine_layout.dewatering_height, 'resolution': mine_layout.hours, 'std': mine_layout.dewatering_std, 'id': id, 'updates': [{ 'slice': 'mine_layout', key: 'dewatering_color', value: newvalueCurrent }] }))
        dispatch(updatesSite({ 'id': id, 'updates': [{ 'slice': 'mine_layout', key: 'dewatering_color', value: newvalueCurrent }] }))
    }

    const customDrilling = v => {
        const newvalueCurrent = !mine_layout.drilling_color
        //dispatch(super_load_constructor({ 'num_processes': mine_layout.drilling_processes, 'resolution': mine_layout.hours, 'avg': mine_layout.drilling_avg, 'std': mine_layout.drilling_std, 'element_name': 'drilling', 'tons_per_day': mine_layout.drilling_tons, 'id': id, 'updates': [{ 'slice': 'mine_layout', key: 'drilling_color', value: newvalueCurrent }] }))
        dispatch(updatesSite({ 'id': id, 'updates': [{ 'slice': 'mine_layout', key: 'drilling_color', value: newvalueCurrent }] }))
    }

    const customComminution = v => {
        const newvalueCurrent = !mine_layout.comminution_color
        dispatch(updatesSite({'id': id, 'updates': [{'slice': 'mine_layout', key: 'comminution_color', value: newvalueCurrent}]}))
        //dispatch(updatesSite({ 'tonnes': mine_layout.comminution_tons, 'std': mine_layout.comminution_std, 'resolution': mine_layout.hours, 'M': mine_layout.comminution_m, 'P': mine_layout.comminution_p, 'F': mine_layout.comminution_f, 'id': id, 'updates': [{ 'slice': 'mine_layout', key: 'comminution_color', value: newvalueCurrent }] }))
    }


    const customRES = v => {
        const newvalueCurrent = !mine_layout.res_color
        dispatch(updatesSite({ 'id': id, 'updates': [{ 'slice': 'mine_layout', key: 'res_color', value: newvalueCurrent }] }))
    }

    const updateMineElements = (v, a) => {
        
        //clear existing mine elements if user changed their mind and changed from yes to no
        if (v[0].value == 'no') {
            // update data of site in backend
            const values = mine_layout.hours=='days' ? 365 : 8760
            dispatch(updatesSite({
                'id': id, 'updates': [
                    { 'slice': 'mine_layout', key: 'hoisting_load', value: Array(values).fill(0) },
                    { 'slice': 'mine_layout', key: 'hoisting_color', value: false },
                    { 'slice': 'mine_layout', key: 'drilling_load', value: Array(values).fill(0) },
                    { 'slice': 'mine_layout', key: 'drilling_color', value: false },
                    { 'slice': 'mine_layout', key: 'ventilation_load', value: Array(values).fill(0) },
                    { 'slice': 'mine_layout', key: 'ventilation_color', value: false },
                    { 'slice': 'mine_layout', key: 'transport_load', value: Array(values).fill(0) },
                    { 'slice': 'mine_layout', key: 'transport_color', value: false },
                    { 'slice': 'mine_layout', key: 'dewatering_load', value: Array(values).fill(0) },
                    { 'slice': 'mine_layout', key: 'dewatering_color', value: false },
                    { 'slice': 'mine_layout', key: 'comminution_load', value: Array(values).fill(0) },
                    { 'slice': 'mine_layout', key: 'comminution_color', value: false },
                    { 'slice': 'mine_layout', key: 'crushing_load', value: Array(values).fill(0) },
                    { 'slice': 'mine_layout', key: 'crushing_color', value: false },
                    { 'slice': 'mine_layout', key: 'total_load', value: Array(values).fill(0) },
                ]
            }))
        }
    }

    const export_png = (id, name) => {  
        var base64String = 'not loaded yet'
        base64String = captureElementAsBase64(name).then(function (base64String) {
            dispatch(exportPNG({ 'id': id,'name':name,'img_string': base64String }))
            return base64String
        });
    }


    return (

        <div className="fullHeight content" id='export_user_steps'>

            <ABB.Heading level={3} text='Step 0: General Mine Data' style={{ 'marginLeft': '0px' }} />
            <p>Please enter general data of the mine (e.g. location, depth, optimization horizon). </p>
            

            <ABB.WithDialog>
                <ABB.Button type="primary-red" shape="pill" text="Enter Data" style={{ 'margin-top': '1px', 'margin-left': '1px' }} /> 
                <ABB.Dialog showCloseButton={true} closeOnEscape={false} closeOnLostFocus={false} hideBackground={false}
                    standardButtonsOnBottom={[{ text: 'Submit', shape: 'pill', type: 'primary-red', handler: (dialog) => dialog.close() },]}
                    onClose={() => export_png(id,'export_general_data')}
                /* title="Input Site Data" */ style={{ top: "10%", left: "50%", margin: "0 0 0 0px", width: "110%", height: "150%", overflow: "hidden" }} >
                    <main> <div> <General_data /></div> </main>
                </ABB.Dialog>
            </ABB.WithDialog>
           
            <ABB.Heading level={3} text='Step 1: Generation of Energy Load Timeseries' style={{ 'marginLeft': '0px' }} />

            <p> Please enter data to generate an energy load series. Use case A: Upload an existing load timeseries,
                Use case B: generate a load timeseries by the mine elements as shown on the right side, Use case C: Combine an existing  use cases A and B.</p>

            <ABB.Heading level={4} text='A: Existing load series' style={{ 'marginLeft': '0px' }} />


            <div style={{ 'display': 'table-cell', 'width': '388px' }} >
                <ABB.Dropdown label={"Do you have a total load timeseries available?"} required={true} searchable={true}
                    //value= {mine_layout.total_load_available}
                    value={(mine_layout.total_load_available !== null) ? [{ label: mine_layout.total_load_available }] : ['no data selected']}
                    onChange={v => updateDataBackend('mine_layout', { key: 'total_load_available', value: v[0].value })}
                >
                    <ABB.DropdownOption label={'yes'} value={'yes'} />
                    <ABB.DropdownOption label={'no'} value={'no'} />
                </ABB.Dropdown>
            </div>


            {mine_layout.total_load_available === 'yes' &&
                <div style={{ 'display': 'table', 'maxWidth': '800px' }}>
                    <div style={{ 'display': 'table-cell', 'width': '388px' }}>
                        <TimeseriesDropzoneSelector onlyDropzone={true} key={mine_layout.hours} value={mine_layout.hours} />
                    </div>
                </div>
            }
            {mine_layout.total_load_available === 'yes' &&
                <div style={{ 'display': 'table-cell', 'width': '388px' }}>
                    <ABB.Dropdown style={{ width: "100%" }} label="Please select a load series for subsequent simulations." required={false} searchable={false}
                        value={(grid.load_series_selector !== null && timeseriesFiles.status == 'loaded') ? [{ label: grid.load_series_selector }] : timeseriesFiles.length == 0 ? ['no data selected'] : ['no data selected']}
                        onChange={(v) => updateSeriesSelector({ key: 'load_series_selector', value: v[0].label })}
                    >
                        {fileList.map((s) => (
                            <ABB.DropdownOption key={s.val} label={s.lab} value={s.val} />
                        ))}
                    </ABB.Dropdown>
                </div>
            }

            <ABB.Heading level={4} text='B: Mining model' style={{ 'marginLeft': '0px' }} />

            <ABB.Dropdown label={"Do you want to select (additional) mine elements?"} required={true}
                value={(mine_layout.add_elements !== null) ? [{ label: mine_layout.add_elements }] : ['no data selected']}
                onChange={v => [updateDataBackend('mine_layout', { key: 'add_elements', value: v[0].value }),
                updateMineElements(v, mine_layout)]}
                style={{ 'display': 'table-cell', 'width': '388px' }}
            >
                <ABB.DropdownOption label={'yes'} value={'yes'} />
                <ABB.DropdownOption label={'no'} value={'no'} />

            </ABB.Dropdown>

            {/* <p> Select which mine elements you want to add and click on their respective symbols to input data</p> */}
            {mine_layout.add_elements == 'yes' &&
                <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}>
                    <div className='list-container'>
                        <div style={{ 'display': 'table-cell' }}>
                            <ABB.Checkbox sizeClass="small" label={'Hoisting'}
                                value={mine_layout.hoisting_color} onChange={customHoisting}
                            />
                        </div>
                        <div style={{ 'display': 'table-cell' }}>
                            <ABB.Checkbox sizeClass="small" label={'Drilling'}
                                value={mine_layout.drilling_color} onChange={customDrilling}
                            />
                        </div>
                        <div style={{ 'display': 'table-cell' }}>
                            <ABB.Checkbox sizeClass="small" label={'Ventilation'}
                                value={mine_layout.ventilation_color}
                                onChange={() => customVentilation()}
                            />
                        </div>
                        <div style={{ 'display': 'table-cell' }}>
                            <ABB.Checkbox sizeClass="small" label={'Transport'}
                                value={mine_layout.transport_color} onChange={customTransport}
                            />
                        </div>
                    </div>
                    <div className='list-container'>
                        <div style={{ 'display': 'table-cell' }}>
                            <ABB.Checkbox sizeClass="small" label={'Dewatering'}
                                value={mine_layout.dewatering_color} onChange={customDewatering}
                            />
                        </div>
                        <div style={{ 'display': 'table-cell' }}>
                            <ABB.Checkbox sizeClass="small" label={'Comminution'}
                                value={mine_layout.comminution_color} onChange={customComminution}
                            />
                        </div>
                        <div style={{ 'display': 'table-cell' }}>
                            <ABB.Checkbox sizeClass="small" label={'Crushing'}
                                value={mine_layout.crushing_color} onChange={customCrushing}
                            />
                        </div>
                    </div>
                </div>
            }

            {mine_layout.add_elements === 'no' && mine_layout.total_load_available === 'no' &&
                <div>
                    <ABB.Heading level={4} text='C: Average Energy Consumption' style={{ 'marginLeft': '0px' }} />

                    <div className='rowC'>
                        <ABB.Input required={true} dataType='number'
                            style={{'marginRight': '16px', 'marginBottom': '16px', 'width': '29%' }}
                            label={'Input the energy value'} suffix={"MWh"}
                            value={mine_layout.energy_consumption_mwh}
                            onValueChange={v => updateDataBackend('mine_layout', { key: 'energy_consumption_mwh', value: emptyStringToNull(parseInt(v)) })}
                        />

                        <ABB.Dropdown label={"Frequency"} required={true}

                            value={[{ label: mine_layout.energy_consumption_freq }]}
                            onChange={v => [updateDataBackend('mine_layout', { key: 'energy_consumption_freq', value: v[0].value })]}
                            style={{ 'display': 'table-cell', 'width': '128px' }}
                        >
                            <ABB.DropdownOption label={'daily'} value={'daily'} />
                            <ABB.DropdownOption label={'monthly'} value={'monthly'} />
                            <ABB.DropdownOption label={'annual'} value={'annual'} />
                        </ABB.Dropdown>
                    </div>
                </div>
            }

            {grid.lifetime > 1 &&
            <div>

                <ABB.Heading level={4} text='D: Energy Consumption Evolution' style={{ 'marginLeft': '0px' }} />

                <div className='list-container'>
                    <ABB.Input required={true} dataType='number'
                        style={{'marginRight': '16px', 'marginBottom': '16px', 'width': '29%' }}
                        label={'How is the yearly load changing'} prefix={'Year 2'} suffix={"MWh"}
                        value={mine_layout.load_year2_mwh}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'load_year2_mwh', value: emptyStringToNull(parseInt(v)) })}
                    />
                    {grid.lifetime > 2 &&
                    <ABB.Input required={true} dataType='number'
                        style={{'marginRight': '16px', 'marginBottom': '16px', 'width': '29%' }}
                        prefix={'Year 3'} suffix={"MWh"}
                        value={mine_layout.load_year3_mwh}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'load_year3_mwh', value: emptyStringToNull(parseInt(v)) })}
                    />
                    }
                    {grid.lifetime > 3 &&
                    <ABB.Input required={true} dataType='number'
                        style={{'marginRight': '16px', 'marginBottom': '16px', 'width': '29%' }}
                        prefix={'Year 4'} suffix={"MWh"}
                        value={mine_layout.load_year4_mwh}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'load_year4_mwh', value: emptyStringToNull(parseInt(v)) })}
                    />
                    }
                    {grid.lifetime > 4 &&
                    <ABB.Input required={true} dataType='number'
                        style={{'marginRight': '16px', 'marginBottom': '16px', 'width': '29%' }}
                        prefix={'Year 5'} suffix={"MWh"}
                        value={mine_layout.load_year5_mwh}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'load_year5_mwh', value: emptyStringToNull(parseInt(v)) })}
                    />
                    }
                    {grid.lifetime > 5 &&
                    <ABB.Input required={true} dataType='number'
                        style={{'marginRight': '16px', 'marginBottom': '16px', 'width': '29%' }}
                        prefix={'Year 6'} suffix={"MWh"}
                        value={mine_layout.load_year6_mwh}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'load_year6_mwh', value: emptyStringToNull(parseInt(v)) })}
                    />
                    }
                    {grid.lifetime > 6 &&
                    <ABB.Input required={true} dataType='number'
                        style={{'marginRight': '16px', 'marginBottom': '16px', 'width': '29%' }}
                        prefix={'Year 7'} suffix={"MWh"}
                        value={mine_layout.load_year7_mwh}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'load_year7_mwh', value: emptyStringToNull(parseInt(v)) })}
                    />
                    }
                    {grid.lifetime > 7 &&
                    <ABB.Input required={true} dataType='number'
                        style={{'marginRight': '16px', 'marginBottom': '16px', 'width': '29%' }}
                        prefix={'Year 8'} suffix={"MWh"}
                        value={mine_layout.load_year8_mwh}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'load_year8_mwh', value: emptyStringToNull(parseInt(v)) })}
                    />
                    }
                    {grid.lifetime > 8 &&
                    <ABB.Input required={true} dataType='number'
                        style={{'marginRight': '16px', 'marginBottom': '16px', 'width': '29%' }}
                        prefix={'Year 9'} suffix={"MWh"}
                        value={mine_layout.load_year9_mwh}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'load_year9_mwh', value: emptyStringToNull(parseInt(v)) })}
                    />
                    }
                    {grid.lifetime > 9 &&
                    <ABB.Input required={true} dataType='number'
                        style={{'marginRight': '16px', 'marginBottom': '16px', 'width': '29%' }}
                        prefix={'Year 10'} suffix={"MWh"}
                        value={mine_layout.load_year10_mwh}
                        onValueChange={v => updateDataBackend('mine_layout', { key: 'load_year10_mwh', value: emptyStringToNull(parseInt(v)) })}
                    />
                    }
                </div>
            </div>
            }
            

            <main> <div> <Addition_Button /></div> </main>

            {mine_layout.total_load !== null && mine_layout.total_load.reduce((a, b) => a + b, 0) > 0 &&
                <div style={{ 'display': 'table-cell' }}>
                    <ABB.Heading level={3} text='Step 2: Input of Renewable Energies' style={{ 'marginLeft': '0px' }} />


                    <p> Select your renewable options and upload timeseries by clicking on the icon the right hand side.</p>

                    <ABB.Checkbox sizeClass="small" label={'Renewable Energy Sources'}
                        value={mine_layout.res_color} onChange={customRES}
                    />
                </div>
            }


            {/* mine_layout.total_load !== null */}

            {/* <ABB.Heading level={3} text='Step 3: Proceed to Optimization' style={{ 'marginLeft': '0px' }} /> */}
            {(window.wind_series_selector !== null && grid.pv_series_selector !== null && mine_layout.total_load !== null && mine_layout.total_load.reduce((a, b) => a + b, 0) > 0) &&
                <ABB.Button type="primary-red" shape="pill" text="Proceed to Optimization" style={{ 'margin-top': '20px', 'margin-left': '1px' }}
                    onClick={() => {
                        dispatch(updateSimNavi({ name: 'simulation1_state', val: 'sim' }));
                        export_png(id,'export_user_steps');
                        export_png(id,'export_overview_figure')
                }}
            />
            }

            {(window.wind_series_selector !== null && grid.pv_series_selector !== null && mine_layout.total_load !== null && mine_layout.total_load.reduce((a, b) => a + b, 0) > 0 && simStates.simulation1_state === "empty") &&
                <ABB.Button type="ghost" shape="pill" text="Reset Values" style={{ 'margin-top': '1px', 'margin-left': '1px' }} onClick={() => ResetDataBackend()} />
            }
        </div>

    )
}

export default User_steps