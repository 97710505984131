import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { api_url } from '../config.js'

const initialState = {
    id: null,
    userName: '',
    role: '',
    email: '',
    customerCompany: '',
    customerName: '',
    customerEmail: '',
    siteName: '',
    siteCountry: '',
}

const register = createAsyncThunk(
    'registerIdent',
    async ident => {
        const response = await axios.post(api_url+'/register', ident)
        return response.data
    }
)

const isUserAuthorized = createAsyncThunk(
    'isUserAuthorized',
    async user => {
        const response = await axios.get(api_url +'/isUserAuthorized/' + user)
        return response.data
    }
)

const identSlice = createSlice({
    name: 'ident',
    initialState,
    reducers: {
        updateIdent(state, action) {
            state[action.payload.key] = action.payload.value
        }
    },
    extraReducers: {
        [register.pending]: (state, action) => { state.status = 'loading' },
        [register.fulfilled]: (state, action) => {
            state.id = action.payload.id
            state.status = 'loaded'
        },
        [register.rejected]: (state, action) => { state.status = 'error' },
        [isUserAuthorized.fulfilled]: (state, action) => {
            state.isAuthorized = action.payload.status
            state.status = 'loaded'
        },
    }
})

const { updateIdent } = identSlice.actions
export { updateIdent, register, isUserAuthorized }
export default identSlice.reducer