import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updatesSite, basicOptimization } from '../../slices/siteSlice'
import { emptyStringToNull } from './../resources/utilities'

import * as ABB from '@abb/abb-common-ux-react'
import '@abb/abb-common-ux-react/styles.css'
import '../../App.css'
import {exportPNG } from '../../slices/siteSlice'
import Slider, { Range } from '../../../node_modules/rc-slider'
import '../../../node_modules/rc-slider/assets/index.css'
import captureElementAsBase64 from './../pdf_export/png_export_function'

const Sim1_part1 = (props) => {

    const dispatch = useDispatch()
    const ident = useSelector(state => state.site.ident)
    const id = ident.id
    const timeseriesFiles = useSelector(state => state.timeseriesFiles)
    const grid = useSelector(state => state.site.grid)
    const wind = useSelector(state => state.site.wind)
    const updateSeriesSelector = update => {
        dispatch(updatesSite({ 'id': id, 'updates': [{ 'slice': 'grid', ...update }] }))
    }
    const updateSeriesSelectorW = update => {
        dispatch(updatesSite({ 'id': id, 'updates': [{ 'slice': 'wind', ...update }] }))
    }
    const fileList = timeseriesFiles.status == 'loaded' ? timeseriesFiles.timeseries.map((t, i) => ({ lab: t.filename, val: i })) : [{ lab: 'empty', val: 0 }]

    const export_png = (id, name) => {
        var base64String = 'not loaded yet'
        base64String = captureElementAsBase64(name).then(function (base64String) {
            dispatch(exportPNG({ 'id': id, 'name': name, 'img_string': base64String }))
            return base64String
        });
    }
    return (
        <div className="fullHeight content" id= 'export_sim1_part1'>
            <ABB.Heading level={3} text={"Select timeseries for optimization:"} style={{ 'marginLeft': '1%' }}/>
            <ABB.Dropdown style={{ width: "70%",'marginLeft': '32px' }} label="Load" required={false} searchable={true}
                value={(grid.load_series_selector !== null && timeseriesFiles.status == 'loaded') ? [{ label: grid.load_series_selector}] : ['no data selected']}
                onChange={(v) => updateSeriesSelector({ key: 'load_series_selector', value: v[0].label })}
            >
                {fileList.map((s) => (
                    <ABB.DropdownOption key={s.val} label={s.lab} value={s.val} />
                ))}
            </ABB.Dropdown>

            <ABB.Dropdown style={{ width: "70%",'marginLeft': '32px' }} label="PV" required={false} searchable={true}
                value={(grid.pv_series_selector !== null && timeseriesFiles.status == 'loaded') ? [{ label:grid.pv_series_selector}] : ['no data selected']}
                onChange={(v) => updateSeriesSelector({ key: 'pv_series_selector', value: v[0].label })}
            >
                {fileList.map((s) => (
                    <ABB.DropdownOption key={s.val} label={s.lab} value={s.val} />
                ))}
            </ABB.Dropdown>

            <ABB.Dropdown style={{ width: "70%",'marginLeft': '32px','marginBottom':'32px' }} label="Wind" required={false} searchable={true}
                value={(wind.wind_series_selector !== null && timeseriesFiles.status == 'loaded') ? [{ label:wind.wind_series_selector }] : ['no data selected']}
                onChange={(v) => updateSeriesSelectorW({ key: 'wind_series_selector', value: v[0].label })}
            >
                {fileList.map((s) => (
                    <ABB.DropdownOption key={s.val} label={s.lab} value={s.val} />
                ))}
            </ABB.Dropdown>
        </div>
    )
}



const Sim1_part2 = (props) => {

    const dispatch = useDispatch()
    const ident = useSelector(state => state.site.ident)
    const id = ident.id
    const timeseriesFiles = useSelector(state => state.timeseriesFiles)
    const grid = useSelector(state => state.site.grid)
    const wind = useSelector(state => state.site.wind)
    const generation = useSelector(state => state.site.generation)
    const mine_layout = useSelector(state => state.site.mine_layout)
    const basic_opt = useSelector(state => state.site.basicOptimization)

    // update data of site in backend
    const updateDataBackend = (site, update) => {
        dispatch(updatesSite({ 'id': id, 'updates': [{ 'slice': site, ...update }] }))
    }

    // double update for range sliders
    const doubleUpdate = (tag1,key1,value1,tag2,key2,value2) => {
        updateDataBackend(tag1, { key: key1, value: value1 })
        updateDataBackend(tag2, { key: key2, value: value2 })
    }
    // image export
    const export_png = (id, name) => {
        var base64String = 'not loaded yet'
        base64String = captureElementAsBase64(name).then(function (base64String) {
            dispatch(exportPNG({ 'id': id, 'name': name, 'img_string': base64String }))
            return base64String
        });
    }

    const opt_tags = ['costs_res_detail','costs_bau_detail','lifetime','break_even','lcoe','pv_real_install','wind_real_install','series_load','series_wind','series_pv','series_el','series_diesel']
    for (let i = 0; i < opt_tags.length; i++) {
        var v = eval('basic_opt.'+opt_tags[i])
        updateDataBackend('basicOptimization', { key: opt_tags[i], value: v})
    }

    const callOptimization = (v, w, z, x) => { //wind, generation, grid, mine_layout
        

        var wind_capex = parseFloat(v.wind_capex)
        var wind_max_install = parseFloat(v.wind_max_install)
        var wind_min_install = parseFloat(v.wind_min_install)
        var wind_opex = parseFloat(v.wind_opex)

        var pv_capex = parseFloat(w.pv_capex)
        var pv_max_install = parseFloat(w.pv_max_install)
        var pv_min_install = parseFloat(w.pv_min_install)
        var pv_opex  = parseFloat(w.pv_opex)

        var price_buy = parseFloat(z.price_buy)
        var price_buy_increase = parseFloat(z.price_buy_increase)
        var physical_power_limit_grid_kW = parseFloat(z.physical_power_limit_grid_kW)
        var diesel_price = parseFloat(z.diesel_price)
        var diesel_price_increase = parseFloat(z.diesel_price_increase)
        var lifetime = parseFloat(z.lifetime)

        var total_load = x.total_load

        
        dispatch(basicOptimization({
            'wind_capex': wind_capex, 'wind_max_install': wind_max_install, 'id': id, 'wind_series_selector': v.wind_series_selector, 'wind_opex': wind_opex, 'wind_min_install': wind_min_install,
            'pv_max_install': pv_max_install, 'pv_capex': pv_capex, 'pv_series_selector': z.pv_series_selector, 'pv_opex': pv_opex, 'pv_min_install': pv_min_install,
            'price_buy': price_buy, 'load_series_selector': z.load_series_selector, 'physical_power_limit_grid_kW': physical_power_limit_grid_kW, 'diesel_price':diesel_price ,'diesel_price_increase':diesel_price_increase,
            'price_buy_increase':price_buy_increase, 'lifetime':lifetime, 'total_load':total_load, 'mwh':x.energy_consumption_mwh, 'freq': x.energy_consumption_freq,
            'load_year2_mwh':x.load_year2_mwh, 'load_year3_mwh':x.load_year3_mwh,'load_year4_mwh':x.load_year4_mwh,'load_year5_mwh':x.load_year5_mwh,
            'load_year6_mwh':x.load_year6_mwh, 'load_year7_mwh':x.load_year7_mwh,'load_year8_mwh':x.load_year8_mwh,'load_year9_mwh':x.load_year9_mwh,
            'load_year10_mwh':x.load_year10_mwh,
        }))
    }
    var fontSizeTab = 12
    var boxWidth = '90%'
    var margTableLeft = '16px'

    return (
        <div className="fullHeight content" id='export_sim1_part2'> 

            <div className= "table_box" >
                <ABB.Heading level={4} text='Renewables' style={{ 'marginLeft': margTableLeft }} />
                <ABB.Input required={true} dataType='number'
                    style = {{'marginLeft': margTableLeft,'width':boxWidth,'marginBottom':margTableLeft,'fontSize':fontSizeTab}}
                    label={'PV Capex [Euro/MW]'}
                    value={generation.pv_capex}
                    onValueChange={v => updateDataBackend('generation', { key: 'pv_capex', value: emptyStringToNull(parseInt(v)) })}
                    onKeyUp = {e => {
                        if (e.key === 'Enter'){callOptimization( wind, generation, grid, mine_layout)}
                    }}
                    /* onLostFocus = {() => callOptimization( wind, generation, grid)} */
                />
                <Slider style={{ height: "15px",'width': boxWidth, color: '#e30b0b', backgroundColor: "transparent" ,'marginLeft': margTableLeft,'marginRight': '64px','marginBottom':margTableLeft}}
                    value={generation.pv_capex}
                    onChange = { v => updateDataBackend('generation', { key: 'pv_capex', value: emptyStringToNull(parseInt(v)) })}
                    onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                    min={0} max={2000000} label="Single (highlight low)" ticks={9}
                />

                
                <ABB.Input required={true} dataType='number'
                    style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}
                    label={'Wind Capex [Euro/MW]'}
                    value={wind.wind_capex}
                    onValueChange={v => updateDataBackend('wind', { key: 'wind_capex', value: emptyStringToNull(parseInt(v)) })}
                    onKeyUp = {e => {
                        if (e.key === 'Enter'){callOptimization( wind, generation, grid, mine_layout)}
                    }}
                    /* onLostFocus = {() => callOptimization( wind, generation, grid)} */
                />
                <Slider style={{ height: "15px",'width': boxWidth, color: '#e30b0b', backgroundColor: "transparent" ,'marginLeft': margTableLeft,'marginRight': '64px','marginBottom':margTableLeft}}
                    value={wind.wind_capex}
                    onChange = { v => updateDataBackend('wind', { key: 'wind_capex', value: emptyStringToNull(parseInt(v)) })}
                    onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                    min={0} max={4000000} label="Single (highlight low)" ticks={9}
                />

                <ABB.Input required={true} dataType='number'
                    style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}
                    label={'PV Opex [Euro/MW/year]'}
                    value={generation.pv_opex}
                    onValueChange={v => updateDataBackend('generation', { key: 'pv_opex', value: emptyStringToNull(parseInt(v)) })}
                    onKeyUp = {e => {
                        if (e.key === 'Enter'){callOptimization( wind, generation, grid, mine_layout)}
                    }}
                    /* onLostFocus = {() => callOptimization( wind, generation, grid)} */
                />
                <Slider style={{ height: "15px",'width': boxWidth, color: '#e30b0b', backgroundColor: "transparent" ,'marginLeft': margTableLeft,'marginRight': '64px','marginBottom':margTableLeft}}
                    value={generation.pv_opex}
                    onChange = { v => updateDataBackend('generation', { key: 'pv_opex', value: emptyStringToNull(parseInt(v)) })}
                    onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                    min={0} max={40000} label="Single (highlight low)" ticks={9}
                />
                <ABB.Input required={true} dataType='number'
                    style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}
                    label={'Wind Opex [Euro/MW/year]'}
                    value={wind.wind_opex}
                    onValueChange={v => updateDataBackend('wind', { key: 'wind_opex', value: emptyStringToNull(parseInt(v)) })}
                    onKeyUp = {e => {
                        if (e.key === 'Enter'){callOptimization( wind, generation, grid, mine_layout)}
                    }}
                    /* onLostFocus = {() => callOptimization( wind, generation, grid)} */
                />
                <Slider style={{ height: "15px",'width': boxWidth, color: '#e30b0b', backgroundColor: "transparent" ,'marginLeft': margTableLeft,'marginRight': '64px','marginBottom':margTableLeft}}
                    value={wind.wind_opex}
                    onChange = { v => updateDataBackend('wind', { key: 'wind_opex', value: emptyStringToNull(parseInt(v)) })}
                    onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                    min={0} max={40000} label="Single (highlight low)" ticks={9}
                />

                <ABB.Input required={true} dataType='number'
                    style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': '0px', 'fontSize': fontSizeTab }}
                    label={'Max PV Capacity [MW]'}
                    value={generation.pv_max_install}
                    onValueChange={v => updateDataBackend('generation', { key: 'pv_max_install', value: emptyStringToNull(parseInt(v)) })}
                    onKeyUp = {e => {
                        if (e.key === 'Enter'){callOptimization( wind, generation, grid, mine_layout)}
                    }}
                    /* onLostFocus = {() => callOptimization( wind, generation, grid)} */
                />
                

                <ABB.Input required={true} dataType='number'
                    style = {{'marginLeft': margTableLeft,'width': boxWidth,'marginBottom':margTableLeft}}
                    label={'Min PV Capacity [MW]'}
                    value={generation.pv_min_install}
                    onValueChange={v => updateDataBackend('generation', { key: 'pv_min_install', value: emptyStringToNull(parseInt(v)) })}
                    onKeyUp = {e => {
                        if (e.key === 'Enter'){callOptimization( wind, generation, grid, mine_layout)}
                    }}
                    /* onLostFocus = {() => callOptimization( wind, generation, grid)} */
                />
                <Range  
                style = {{width : boxWidth,'marginLeft': margTableLeft,'marginBottom': '32px'}}
                min={0} 
                max={250} 
                allowCross = 'false'
                defaultValue={[generation.pv_min_install, generation.pv_max_install]}  
                value={[generation.pv_min_install, generation.pv_max_install]}   
                onChange = {value => doubleUpdate('generation','pv_min_install',emptyStringToNull(parseInt(value[0])),'generation','pv_max_install',emptyStringToNull(parseInt(value[1])))}
                onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                />

                <ABB.Input required={true} dataType='number'
                    style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': '0px', 'fontSize': fontSizeTab }}
                    label={'Max Wind Capacity [MW]'}
                    value={wind.wind_max_install}
                    onValueChange={v => updateDataBackend('wind', { key: 'wind_max_install', value: emptyStringToNull(parseInt(v)) })}
                    onKeyUp = {e => {
                        if (e.key === 'Enter'){callOptimization( wind, generation, grid, mine_layout)}
                    }}
                    /* onLostFocus = {() => callOptimization( wind, generation, grid)} */
                />
                

                <ABB.Input required={true} dataType='number'
                    style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}
                    label={'Min Wind Capacity [MW]'}
                    value={wind.wind_min_install}
                    onValueChange={v => updateDataBackend('wind', { key: 'wind_min_install', value: emptyStringToNull(parseInt(v)) })}
                    onKeyUp = {e => {
                        if (e.key === 'Enter'){callOptimization( wind, generation, grid, mine_layout)}
                    }}
                    /* onLostFocus = {() => callOptimization( wind, generation, grid)} */
                />
                <Range  
                style = {{width : boxWidth,'marginLeft': margTableLeft,'marginBottom': '64px'}}
                min={0} 
                max={250} 
                allowCross = 'false'
                defaultValue={[wind.wind_min_install, wind.wind_max_install]}  
                value={[wind.wind_min_install, wind.wind_max_install]}   
                onChange = {value => doubleUpdate('wind','wind_min_install',emptyStringToNull(parseInt(value[0])),'wind','wind_max_install',emptyStringToNull(parseInt(value[1])))}
                onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                />

            </div>

            <div className="table_box" >
                <ABB.Heading level={4} text='Grid' style={{ 'marginLeft': margTableLeft }} />
                <ABB.Input required={true} dataType='number'
                    style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}
                    label={'Electricity Price [Euro/MWh]'}
                    value={grid.price_buy}
                    onValueChange={v => updateDataBackend('grid', { key: 'price_buy', value: emptyStringToNull(parseInt(v)) })}
                    onKeyUp = {e => {
                        if (e.key === 'Enter'){callOptimization( wind, generation, grid, mine_layout)}
                    }}
                    /* onLostFocus = {() => callOptimization( wind, generation, grid)} */
                />

                <Slider style={{ height: "15px",'width': boxWidth, color: '#e30b0b', backgroundColor: "transparent" ,'marginLeft': margTableLeft,'marginRight': '64px','marginBottom':margTableLeft}}
                    value={grid.price_buy}
                    onChange = { v => updateDataBackend('grid', { key: 'price_buy', value: emptyStringToNull(parseInt(v)) })}
                    onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                    min={0} max={1000} label="Single (highlight low)" ticks={9}
                />

                <ABB.Input required={true} dataType='number'
                    style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}
                    label={'Electricity Price Increase per Year [%]'}
                    value={grid.price_buy_increase}
                    onValueChange={v => updateDataBackend('grid', { key: 'price_buy_increase', value: emptyStringToNull(parseInt(v)) })}
                    onKeyUp = {e => {
                        if (e.key === 'Enter'){callOptimization( wind, generation, grid, mine_layout)}
                    }}
                    /* onLostFocus = {() => callOptimization( wind, generation, grid)} */
                />

                <Slider style={{ height: "15px",'width': boxWidth, color: '#e30b0b', backgroundColor: "transparent" ,'marginLeft': margTableLeft,'marginRight': '64px','marginBottom':margTableLeft}}
                    value={grid.price_buy_increase}
                    onChange = { v => updateDataBackend('grid', { key: 'price_buy_increase', value: emptyStringToNull(parseInt(v)) })}
                    onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                    min={0} max={100} label="Single (highlight low)" ticks={9}
                />

                <ABB.Input required={true} dataType='number'
                    style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}
                    label={'Max Grid Capacity [MW]'}
                    value={grid.physical_power_limit_grid_kW}
                    onValueChange={v => updateDataBackend('grid', { key: 'physical_power_limit_grid_kW', value: emptyStringToNull(parseInt(v)) })}
                    onKeyUp = {e => {
                        if (e.key === 'Enter'){callOptimization( wind, generation, grid, mine_layout)}
                    }}
                    /* onLostFocus = {() => callOptimization( wind, generation, grid)} */
                />
                <Slider style={{ height: "15px",'width': boxWidth, color: '#e30b0b', backgroundColor: "transparent" ,'marginLeft': margTableLeft,'marginRight': '64px','marginBottom':margTableLeft}}
                    value={grid.physical_power_limit_grid_kW}
                    onChange = { v => updateDataBackend('grid', { key: 'physical_power_limit_grid_kW', value: emptyStringToNull(parseInt(v)) })}
                    onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                    min={1} max={200} label="Single (highlight low)" ticks={9}
                />

            </div>

            

            <div className="table_box" >
                <ABB.Heading level={4} text='Diesel' style={{ 'marginLeft': margTableLeft }} />
                <ABB.Input required={true} dataType='number'
                    style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}
                    label={'Diesel Price [Euro/MWh]'}
                    value={grid.diesel_price}
                    onValueChange={v => updateDataBackend('grid', { key: 'diesel_price', value: emptyStringToNull(parseInt(v)) })}
                    onKeyUp = {e => {
                        if (e.key === 'Enter'){callOptimization( wind, generation, grid, mine_layout)}
                    }}
                    /* onLostFocus = {() => callOptimization( wind, generation, grid)} */
                />

                <Slider style={{ height: "15px",'width': boxWidth, color: '#e30b0b', backgroundColor: "transparent" ,'marginLeft': margTableLeft,'marginRight': '64px','marginBottom':margTableLeft}}
                    value={grid.diesel_price}
                    onChange = { v => updateDataBackend('grid', { key: 'diesel_price', value: emptyStringToNull(parseInt(v)) })}
                    onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                    min={0} max={400} label="Single (highlight low)" ticks={9}
                />

                <ABB.Input required={true} dataType='number'
                    style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}
                    label={'Diesel Price Increase per Year [%]'}
                    value={grid.diesel_price_increase}
                    onValueChange={v => updateDataBackend('grid', { key: 'diesel_price_increase', value: emptyStringToNull(parseInt(v)) })}
                    onKeyUp = {e => {
                        if (e.key === 'Enter'){callOptimization( wind, generation, grid, mine_layout)}
                    }}
                    /* onLostFocus = {() => callOptimization( wind, generation, grid)} */
                />


                <Slider style={{ height: "15px",'width': boxWidth, color: '#e30b0b', backgroundColor: "transparent" ,'marginLeft': margTableLeft,'marginRight': '64px','marginBottom':margTableLeft}}
                    /* handleStyle={{  borderColor: 'red', backgroundColor: "white" }}
                    trackStyle={{ backgroundColor: 'red' }} */
                    value={grid.diesel_price_increase}
                    onChange = { v => updateDataBackend('grid', { key: 'diesel_price_increase', value: emptyStringToNull(parseInt(v)) })}
                    onAfterChange={()=> callOptimization( wind, generation, grid, mine_layout)}
                    min={0} max={100} label="Single (highlight low)" 
                />
                
                <ABB.Heading level={4} text='General' style={{ 'marginLeft': margTableLeft,'marginTop': '300px' }} />
                <ABB.Input required={true} dataType='number'
                    style={{ 'marginLeft': margTableLeft,'width': boxWidth, 'marginBottom': margTableLeft, 'fontSize': fontSizeTab }}
                    label={'Optimization horizon [years]'}
                    value={grid.lifetime}
                    onValueChange={v => updateDataBackend('grid', { key: 'lifetime', value: emptyStringToNull(parseInt(v)) })}
                    onKeyUp={e => {
                        if (e.key === 'Enter') { callOptimization(wind, generation, grid, mine_layout) }
                    }}
                /* onLostFocus = {() => callOptimization( wind, generation, grid)} */
                />

                <Slider style={{ height: "15px",'width': boxWidth, color: '#e30b0b', backgroundColor: "transparent", 'marginLeft': margTableLeft, 'marginRight': '64px', 'marginBottom': margTableLeft }}
                    value={grid.lifetime}
                    onChange={v => updateDataBackend('grid', { key: 'lifetime', value: emptyStringToNull(parseInt(v)) })}
                    onAfterChange={() => callOptimization(wind, generation, grid, mine_layout)}
                    min={1} max={10} label="Single (highlight low)" ticks={9}
                />
                
                {wind.wind_series_selector != null && grid.pv_series_selector != null && mine_layout.total_load !== null &&
                    <ABB.Button type="primary-red" shape="pill" text="Optimization" style={{ 'margin-top': '80px', 'margin-left': "1%", 'margin-bottom': "10%" }}
                        onClick={v => {
                            callOptimization(wind, generation, grid, mine_layout);
                            export_png(id, 'export_sim1_part1')
                            export_png(id, 'export_sim1_part2')
                            //export_png(id, 'export_plot_LoadDistribution')
                            export_png(id, 'export_result_numbers')
                        }
                        }

                    />
                }
            </div>

            {/* <div style={{ 'width': '500px', 'margin-top': '50px','margin-bottom': '50px','margin-left': '50px' }} >
                {wind.wind_series_selector != null && grid.pv_series_selector != null && grid.load_series_selector !== null &&
                    <ABB.Button type="primary-red" shape="pill" text="Basic Optimization" onClick={v => callOptimization(wind, generation, grid)} />
                }
                {(wind.wind_series_selector == null || grid.pv_series_selector == null || grid.load_series_selector == null) &&
                    <ABB.Button type="gray" shape="pill" text="Select timeseries for optimization" />
                }
            </div> */}
        </div>
    )
}

export { Sim1_part1, Sim1_part2 }