import React from 'react'
import Welcome from './welcome'
import * as ABB from '@abb/abb-common-ux-react'
import '@abb/abb-common-ux-react/styles.css'
import '../../App.css'
import Register_skip from './register_skip'

const Landing = () => {


    return (
        <div className="landing_full">
            <div className="contentBox" >   {/* create a text content box as specified in src/App.css */}
                <div className='landing_top'>  {/* create input box on top  */}
                    <ABB.Heading level={3} text='DEV - Mine Electrification Analysis (MEA)' style={{ 'marginLeft': '0px' }} />
                    <Register_skip/>
                    <ABB.Heading level={4} text='The current status of the tool allows the user to optimize the investment into renewable energies for a green- or brownfield mine.' style={{ 'marginLeft': '0px' }} />
                    <ABB.Heading level={4} text='In a first step Electricity Load and Generation data for a mine site must be entered. The second step offers online optimization for renewable capacity optimization under different scenarios.' style={{ 'marginLeft': '0px' }} />
                       
                    <p> The MEA tool has been developed within the research project eMine Power at SECRC and DECRC (2022-2024). Main tool development activities by Loss, Li, Schoenfisch (DECRC). </p>   
                    <p>The tool maintenance is supported by EUOPC, please contact <a href="mailto:petr.herrmann@cz.abb.com">petr.herrmann@cz.abb.com</a> for feedback, questions, or when you experience unexpected behaviour.</p>             
                    <div className = 'fleft'>
                                 
                    <Welcome />
                    </div>
                    
                </div>

            </div>
        </div>
        
    )
}

export default Landing