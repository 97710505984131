import React, {useEffect}  from 'react'
import { BrowserRouter, Switch, Route} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import Site from './components/resources/site'
import Landing from './components/joint_frontend/landing'
import * as ABB from '@abb/abb-common-ux-react'
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.min.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.min.css';
import { jwtDecode } from 'jwt-decode';
import { isUserAuthorized } from './slices/identSlice'
import is from 'date-fns/esm/locale/is/index.js'

//import cors from "cors";

function App() {
    //const corsOrigin = {
    //    origin: 'http://localhost:3000', //or whatever port your frontend is using
    //    credentials: true,
    //    optionSuccessStatus: 200
    //}
    //cors(corsOrigin)

    const dispatch = useDispatch()
    const isAuth = useSelector(state => state.ident)// const isAuth = true;
    let token;
    let projectId;
    let decodedToken = {}; 


    useEffect(() => {
        if (decodedToken.email) {
            dispatch(isUserAuthorized(decodedToken.email))
        }
    }, [decodedToken.email])

    const isTokenValid = token => {
        if (token == null || token.length == 0)
            return false
        decodedToken = jwtDecode(token)
        let currentDate = new Date();
        if (token || decodedToken.exp * 1000 >= currentDate.getTime()) 
        {
            return true
        }
        return false
    }

    //token and project id is stored as session vars
    token = sessionStorage.getItem('id_token');
    projectId = sessionStorage.getItem('project_id');

    if (!isTokenValid(token))
    {
        //gets query string and extracts token and project id (send in state parameter)
        var query = new URLSearchParams(window.location.hash.replace('#','?'));
        if (query.size > 0)
        {
            token = query.get('id_token');
            projectId = query.get('state');
            sessionStorage.setItem('id_token',token);

            //if authentication i required during working with project (token expires), project id is send in url
            if (projectId != 'null' && projectId.length > 0)
            {
                const url = new URL(window.location.origin);
                url.pathname = '/' + projectId;
        
                window.history.pushState({}, "", url); 
            }
        }
        else
        {
            //redirects to SSO, uses state parameter for project id, which is used if token expires
            var link = 'https://login.microsoftonline.com/abb.onmicrosoft.com/oauth2/v2.0/authorize?client_id=590fb715-3a5a-4710-91d2-71a2ee90d456&response_type=id_token&scope=openid+email+profile&state='+ projectId +'&response_mode=fragment&prompt=select_account&nonce=1&claims=%7b%22id_token%22:%7b%22upn%22:%7b%22essential%22:true%7d%7d%7d&redirect_uri='+ encodeURIComponent(window.location.origin);
            window.location.replace(link)    
        }
    }

    //gets project id from url and stores if valid
    if (window.location.pathname.substring(1).length > 0)
    {
        var pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        var id = window.location.pathname.substring(1);
        if (id.length > 1 && pattern.test(id) === true)
        {
            projectId = id;
            sessionStorage.setItem('project_id',id);
        }
    }

    return (
        <BrowserRouter>
            <ABB.AppContainer theme='light'>

                <ABB.AbbBar productName= "DEV - Mine Electrification Analysis (MEA)">Welcome {decodedToken.name}, {decodedToken.email}&nbsp;</ABB.AbbBar>
                { (!isAuth.isAuthorized && isAuth.status == 'loaded') &&
                    <ABB.Dialog
                        isOpen={true}
                        showCloseButton={true}
                        closeOnEscape={false}
                        closeOnLostFocus={false}
                        hideBackground={true}
                        title="DEV - Mine Electrification Analysis (MEA) - Unauthorized user"
                        style={{ width: "800px" }} >
                        <main> <div>User {decodedToken.name} is not authorized to use this application! Please contact <a href="mailto:petr.herrmann@cz.abb.com">petr.herrmann@cz.abb.com</a> to grant your user access.</div> </main>
                    </ABB.Dialog>
                }
                { (isAuth.isAuthorized) &&
                    <Switch>
                        <Route path='/:handle'  component={Site} />
                        <Route path="/" component={Landing}  />
                    </Switch>                
                }

                <ABB.AppFooter showCopyright={false} showLogo={true} />
            </ABB.AppContainer> 
        </BrowserRouter>
    )
}

export default App
