import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updatesSite, load_summation, load_summation_hours, exportPNG, load_total } from '../../slices/siteSlice'
import * as ABB from '@abb/abb-common-ux-react'
import '@abb/abb-common-ux-react/styles.css'
import '../../App.css'
import export_general_data from './../mining_components/general_data'
import captureElementAsBase64 from './../pdf_export/png_export_function'

const Addition_Button = () => {
    const dispatch = useDispatch()
    const ident = useSelector(state => state.site.ident)
    const id = ident.id
    const grid = useSelector(state => state.site.grid)
    const mine_layout = useSelector(state => state.site.mine_layout)

    // update data of site in backend
    const updateDataBackend = (site, update) => {
        dispatch(updatesSite({ 'id': id, 'updates': [{ 'slice': site, ...update }] }))
    }

    //regularly update load series
    const load_tags = ['total_load', 'crushing_load', 'drilling_load', 'transport_load', 'dewatering_load', 'hoisting_load', 'ventilation_load', 'comminution_load']
    for (let i = 0; i < load_tags.length; i++) {
        var v = eval('mine_layout.' + load_tags[i])
        updateDataBackend('mine_layout', { key: load_tags[i], value: v })
    }

    const export_png = (id, name) => {
        var base64String = 'not loaded yet'
        base64String = captureElementAsBase64(name).then(function (base64String) {
            dispatch(exportPNG({ 'id': id, 'name': name, 'img_string': base64String }))
            return base64String
        });
    }
    const callLoadSummation = (a, b, c) => {

        if ((mine_layout.total_load_available === 'no' && mine_layout.add_elements === 'no' && mine_layout.energy_consumption_mwh > 0))
        {
            dispatch(load_total({ 'mwh': mine_layout.energy_consumption_mwh, 'freq': mine_layout.energy_consumption_freq}))
            return
        }

        const rand = mine_layout.hours == 'days' ? 365 : 8760


        if (a.crushing_color == false) { var crushing_load = new Array(rand).fill(0) } else { var crushing_load = a.crushing_load }
        if (a.drilling_color == false) { var drilling_load = new Array(rand).fill(0) } else { var drilling_load = a.drilling_load }
        if (a.transport_color == false) { var transport_load = new Array(rand).fill(0) } else { var transport_load = a.transport_load }
        if (a.dewatering_color == false) { var dewatering_load = new Array(rand).fill(0) } else { var dewatering_load = a.dewatering_load }
        if (a.hoisting_color == false) { var hoisting_load = new Array(rand).fill(0) } else { var hoisting_load = a.hoisting_load }
        if (a.ventilation_color == false) { var ventilation_load = new Array(rand).fill(0) } else { var ventilation_load = a.ventilation_load }
        if (a.comminution_color == false) { var comminution_load = new Array(rand).fill(0) } else { var comminution_load = a.comminution_load }

        if (mine_layout.hours == 'days') {
            dispatch(load_summation({
                'crushing': crushing_load,
                'drilling': drilling_load,
                'transport': transport_load,
                'dewatering': dewatering_load,
                'hoisting': hoisting_load,
                'ventilation': ventilation_load,
                'comminution': comminution_load,
                'load_series_selector': b.load_series_selector,
                'id': id
            }))
        } else if (mine_layout.hours == 'hours') {
            dispatch(load_summation_hours({
                'crushing': crushing_load,
                'drilling': drilling_load,
                'transport': transport_load,
                'dewatering': dewatering_load,
                'hoisting': hoisting_load,
                'ventilation': ventilation_load,
                'comminution': comminution_load,
                'load_series_selector': b.load_series_selector,
                'id': id
            }))
        }
        
    }

    const any_mine_element = (mine_layout.crushing_color == true || mine_layout.drilling_color == true || mine_layout.transport_color == true || mine_layout.dewatering_color == true ||
        mine_layout.hoisting_color == true || mine_layout.ventilation_color == true || mine_layout.comminution_color == true)

    const calc_ready = ((mine_layout.total_load_available == 'yes' && grid.load_series_selector !== null) || 
                        (mine_layout.add_elements == 'yes' && any_mine_element == true) ||
                        (mine_layout.total_load_available === 'no' && mine_layout.add_elements === 'no' && mine_layout.energy_consumption_mwh > 0))

    return (
        <div className="fullHeight content">


            {calc_ready &&
                <ABB.Button type="primary-red" shape="pill" text="Generate Total Load Timeseries" style={{ 'margin-top': '20px', 'margin-left': '1px' }}
                    onClick={() => {
                        callLoadSummation(mine_layout, grid, id);
                        export_png(id, export_general_data)
                    }} />
            }

            {!calc_ready &&
                <ABB.Button type="ghost" shape="pill" text="Generate Total Load Timeseries" style={{ 'margin-top': '20px', 'margin-left': '1px' }} onClick={() => callLoadSummation(mine_layout, grid, id)} />}



        </div>

    )
}

export default Addition_Button